.modal_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  margin-bottom: 25px;
  border-radius: 5px;
  transition: 0.5s;
  padding: 15px;

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__name {
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
    }

    &__buttons {
      display: flex;

      &__preview,
      &__add {
        margin-left: 10px;
        border-radius: 3px;
        height: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }

      &__preview {
        border: 2px black solid;
        width: 83px;
        color: black;
        transition: 0.3s;

        &:hover {
          color: white;
          background-color: black;
        }
      }
      &__add {
        background-color: #1890ff;
        width: 125px;
        color: white;
      }
    }
  }
  &__details {
    width: 100%;
  }
}

.custom_link {
  border-radius: 5px;
  background-color: black;
  color: white;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500px;
  cursor: pointer;
  margin-bottom: 20px;
  &__text {
    margin-left: 10px;
  }
}
.assessment_button {
  border: 2px black solid;
  background-color: white;
  font-weight: 600;
  padding: 10px;
  flex-direction: row !important;
  justify-content: space-between;

  // & .modal_button__top__buttons {
  //   &__preview {
  //     color: white !important;
  //     border: 2px white solid;
  //   }
  //   &__add {
  //     background-color: white;
  //     color: #1890ff;
  //   }
  // }
}
