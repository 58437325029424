.verify {
  margin-top: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__button {
    cursor: pointer;
    color: #ff3030;
    font-size: 18px;
    font-weight: 200px;
  }
}
