@import './variable.scss';
.outer_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: Roboto;
  &__logo {
    position: absolute;
    top: 70px;
    height: 64px;
    & > a > svg {
      height: 100%;
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    min-height: calc(100vh - 62px);
    padding: 200px 0px 50px 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__card {
      min-width: 500px;
      min-height: 200px;
      height: max-content;
      margin-bottom: 100px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 18px 19px 86px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      padding: 40px;

      &__topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
        &__title {
          font-size: 30px;
          font-weight: 900;
        }
        &__action {
          & > a {
            font-size: 20px;
            color: #ff3030;
            &::after {
              font-size: 20px;
              font-weight: 700;
              margin-left: 5px;
              content: '→';
            }
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__footer {
    width: 100%;
    height: 62px;
    background-color: black;
    bottom: 0px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
  }
}

@media #{$phone-landscape} {
  .outer_page {
    &__logo {
      width: 200px;
      height: auto;
      top: 35px;
    }
    &__content {
      padding-top: 100px;
      &__card {
        width: 330px;
        min-width: unset;
      }
    }
    &__footer {
      text-align: center;
    }
  }
}
