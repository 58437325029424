.timer {
  width: 105px;
  height: 45px;
  background-color: #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  &__icon {
    height: 100%;
  }
  &__time {
    width: 100%;
    text-align: center;
  }
}
