@import '../../app/OuterPages/variable.scss';

.submit_comp {
  height: 70px;
  width: 400px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: 500;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  & > .loading {
    height: 50%;
    animation: roatate 1s infinite;
  }

  &.check_button {
    transition: 0.5;
    width: 70px;
    border-radius: 100px;
    & > .check {
      height: 30%;
    }
  }
}

@keyframes roatate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}

@media #{$phone-landscape} {
  .submit_comp {
    width: 300px;
  }
}
