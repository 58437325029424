.login {
  &__logo {
    height: 100px;
    width: auto;
    margin: 50px;
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    &__sub {
      font-size: 20px;
      font-weight: 200;
      padding-top: 20px;

      & > a {
        color: #ff3636;
        text-decoration: none;
      }
    }
  }
}
