.login {
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &__sub {
    font-size: 18px;
    padding-top: 10px;

    & > a {
      color: black;
      text-decoration: none;
    }
  }
}
