.message_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
  &__icon {
    height: 100px;
    margin-bottom: 25px;
  }
  &__title {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
  }
  &__description {
    font-size: 20px;
    font-weight: 200;
    text-align: start;
    text-align: center;
  }

  &__mail {
    background-color: #f5f5f5;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
  }
}
