@import '../../app/OuterPages/variable.scss';
.input_comp {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  & > input {
    height: 70px;
    width: 400px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 20px;
    font-family: 'Roboto';
    text-indent: 15px;
    &.error {
      border: red;
      // background-color: #ff3030;
      border: 1px red solid;
    }

    &:invalid {
      border: 1px red solid;
    }
    &:focus {
      outline: none;
    }
  }
  & > textarea {
    height: 100px;

    min-width: 300px;
    max-width: 300px;
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 20px;
    font-family: 'Roboto';
    text-indent: 15px;
  }

  & .subfield {
    height: 30px;
    font-size: 15px;
    padding-top: 5px;
    font-weight: 700;
    color: #ff3030;
  }
}

@media #{$phone-landscape} {
  .input_comp {
    & > input {
      width: 300px;
    }
  }
}
