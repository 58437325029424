@import '../../OuterPages/variable.scss';
.signup {
  display: flex;
  flex-direction: column;
  &__logo {
    height: 70px;
    width: auto;
    margin: 50px;
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    &__sub {
      font-size: 18px;
      padding-top: 20px;
      & > a {
        color: #ec5049;
        text-decoration: none;
      }
    }
  }
}

.form_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 30px;
  justify-items: center;
}

@media #{$phone-landscape} {
  .form_grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}
