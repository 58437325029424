@import '../assets/fonts/stylesheet.css';

/* base */
html,
body,
#main,
#root,
#app,
#login,
#result,
#admin,
#main,
#signup,
.ant-row-flex,
.ant-layout {
  height: 100%;
}

/* admin */
#admin .ant-table-body .ant-table-row {
  background-color: #fff !important;
}

#admin .ant-layout-header {
  padding: 0;
}

#admin .ant-layout-header .ant-row-flex {
  padding: 0 45px 0 45px !important;
  overflow-y: hidden;
}

#admin .items-list-table .ant-card-body {
  padding: 0;
}

#admin .ant-pagination {
  margin-right: 16px !important;
}

.ant-layout-sider-zero-width-trigger {
  top: 10px !important;
}

main.ant-layout-content {
  height: auto !important;
  min-height: auto !important;
}

.tab-form-button {
  margin-top: 15px !important;
}

/* dashboard */
.detail-statistic {
  text-align: center;
}

/* report */
#report .ant-input-disabled {
  color: #555;
}

#report .footer {
  margin-top: 50px;
  font-size: 0.7rem;
  text-align: center;
}

#report .ant-card {
  margin: 40px 15px !important;
}

#report .report-content {
  margin: 40px 15px !important;
}

#report,
.ant-row-flex {
  overflow-x: hidden;
}

#report .ant-input-disabled {
  color: #555;
}

#report .footer {
  margin-top: 60px;
  font-size: 0.7rem;
  text-align: center;
}

#report .logo {
  width: 150px;
  display: block;
  margin: 10px auto 20px;
}

#report .required-start {
  font-size: 1.3rem;
  color: red;
  position: absolute;
  margin-left: -14px;
  margin-top: 11px;
}

ul {
  list-style: none !important;
}

.dragging-helper-class {
  list-style: none;
}
