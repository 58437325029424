.activate {
  height: 300px;
  display: flex;
  padding: 30px 0px 20px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &.loading {
    justify-content: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__icon {
      width: 80px;
      height: 80px;
      margin-bottom: 40px;

      &.loading {
        width: 60px;
        height: 60px;
        animation: roatate 2s infinite;
      }
    }
    &__title {
      font-size: 30px;
      font-weight: 700;
      text-align: center;
    }
  }

  &__button {
    font-size: 20px;
    font-weight: 200;
    color: #ff3636;
    margin-top: 40px;
    cursor: pointer;
  }
}

@keyframes roatate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
